import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Finding, documenting, and following wild honeybee colonies in Colorado."
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1>Colorado Wild Bees</h1>
          <p>
          Finding, documenting, and following wild honeybee colonies in Colorado.
          </p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          {/* <div className="col-12">
            <h2 className="title-3 text-dark mb-3">The Bees</h2>
          </div> */}
          {markdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </h2>
                  <p dangerouslySetInnerHTML={{__html: edge.node.excerpt}}></p>
                  <Link to={edge.node.frontmatter.path}>Read more...</Link>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="mt-2" to="/bees">
              <h3><i>Learn More About Wild Bees...</i></h3>
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Getting Involved</h2>
          </div>
          {json.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <Link to={edge.node.path}>
                <div className="feature">
                  {edge.node.image && (
                    <div className="feature-image">
                      <img src={withPrefix(edge.node.image)} />
                    </div>
                  )}
                  <h2 className="feature-title">{edge.node.title}</h2>
                  <div className="feature-content">{edge.node.description}</div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/\\/bees\\//"}, frontmatter: {featured: {eq: true}, draft: {eq: false}}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt(format: HTML)
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          path
        }
      }
    }
  }
`;

export default Home;
